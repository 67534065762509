<template>
    <div  class="communal" ref="communal" >
        <!-- 顶部固定 -->
        <div class="top-fixed"  ref="topFixed">
          <div class="container"  >
                <el-row :gutter="20" class="p-b-10 font-s14 c66">
                    <el-col :span="6">发布日期：</el-col>
                    <el-col :span="4">所属分类：</el-col>
                    <el-col :span="4">状态：</el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <el-date-picker class="el-long" type="daterange" range-separator="-" start-placeholder="起" end-placeholder="终" v-model="search.date" value-format="yyyy-MM-dd" :editable="false"></el-date-picker>
                    </el-col>
                    <el-col :span="4">
                        <el-select clearable v-model="search.sex" placeholder="请选择">
                            <el-option  v-for="item in sexList" :key="item.value" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="4">
                         <el-select clearable v-model="search.grade" placeholder="请选择">
                            <el-option  v-for="item in sexList" :key="item.value" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="5">
                        <el-input placeholder="请输入关键字查询" v-model="search.txt"></el-input>
                    </el-col>
                    <el-col :span="5">
                        <el-button type="success" @click="getList">搜索</el-button>
                        <!-- <el-button type="success" plain @click="deriveBtn">导出</el-button> -->
                    </el-col>
                </el-row>
          </div>
          <div class="interval"></div>
        </div>
        <!-- 内容 -->
        <div class="content"  >
            <div class="container" :style="{height:contentHeight+'px'}">
                <div class="el-long clearfix  " style="padding:10px 10px 0">
                    <div class="fl">
                        <!-- <el-button type="success"  size="small" style="margin-left: 10;" @click="operationBtn('新增','')">新增量表</el-button> -->
                        <!-- <el-upload
                            class="upload-demo i-block mr-20"
                            :action="actionUrl()"
                            :show-file-list='false'
                            :on-success="handleAvatarSuccess"
                            :before-upload="beforeAvatarUpload"
                            style="margin-left: 10px;margin-right: 10px; "
                            >
                            <el-button  size="small" type="primary" plain>导入</el-button>
                        </el-upload> -->
                    </div>
                    </div>
                    <template>
                        <el-table
                            ref="refTable"
                            :data="list"
                            :header-row-class-name="'table_header'"
                            style="width: 100%"
                            :height="contentHeight-60"
                            v-loading="loading">
                        <!-- 勾选 -->
                            <el-table-column prop="vendor_name" label="量表标题" min-width="200" show-overflow-tooltip/>
                            <el-table-column prop="drug_name" label="所属分类" min-width="200" show-overflow-tooltip/>
                            <el-table-column prop="specification" label="发布时间" min-width="150" show-overflow-tooltip/>
                            <el-table-column prop="is_agent_name" label="状态" min-width="150" show-overflow-tooltip/>
                            <!-- <el-table-column label="操作" fixed="right" min-width="120" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-button type="text" @click="operationBtn('编辑',scope.row)">编辑</el-button>
                                    <el-button type="text" @click="operationBtn('删除',scope.row)">删除</el-button>
                                    <el-button type="text" @click="operationBtn('查看',scope.row)">查看</el-button>
                                </template>
                            </el-table-column> -->
                        </el-table>
                    </template>
            </div>
        </div>
        <!-- 分页 100-->
        <div class="paging" ref="paging">
              <el-pagination
                            @current-change="handleCurrentChange"
                            @size-change="handleSizeChange"
                            :current-page="currentPage"
                            :page-sizes="[10, 50, 100, 2000]"
                            :page-size="psize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totals"
                ></el-pagination>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
      topHeight: "", //顶部搜索的高度
      mainHeight: "", //获取当前容器的高度
      pagingHeight: "", //获取分页的高度
      contentHeight: 0, //内容的高度
      totals: 0,
      pages: "1",
      psize: 10,
      currentPage: 1, //默认显示页
      search:{
          date:'',
          sex:'0',
          grade:'',
          user:'0',
          txt:''
      },
        sexList:[
            {id:'0',name:'不限'},
            {id:'1',name:'男'},
            {id:'2',name:'女'}
        ],
        loading: false,
        list:[{drug_name:'12'}],
        lineRow:''
    };
  },
  mounted() {
    var that=this;
    that.common.Count.pageResize(that);
    window.onresize = function temp() {
                that.common.Count.pageResize(that);
    };
  },
  methods: {
    handleCurrentChange(val) {
      this.pages = val;
      this.getList()
    },
    handleSizeChange(val) {
      this.pages='1'
      this.psize = val;
      this.getList()
    },
    //获取数据
    getList(operationName) {
      var db = {
        pages: this.pages,
        pnums: this.psize,
        txt: this.search.content,
      };
      this.loading = true;
      this.common.communalApi.getTableList({ url: StandardizeJeedList, db: db },this.getData);
      this.getJeedList({});
    },
    getData(list, totals) {
      this.$set(this, "list", list);
      this.$set(this, "totals", totals);
      this.loading = false;
    },
    operationBtn(btnName,row) {
      this.clearForm();
      this.durgTitle = btnName;
      this.lineRow=row
      if(btnName=='新增'){
        this.durgVisible = true;
      }else if(btnName=='编辑'){
       
        this.getInfo({jdde_id:row.jdde_id});
        // 详情
        this.dataInfo(row);
      }else if(btnName=='删除'){//删除
    //    this.common.communalApi.buttonMsg({ url: DelStandardizeJeed, db: {jdde_re_ids:row.jdde_re_id}, title: '确定删除该商业公司？'}, this, this.getList)
      }else if(btnName=='查看'){
        this.$router.push({
          name: "scaleMangeInfo",
          query: { lineId: row.id }
        });
      }
    },
    dataInfo(row){
    //   var db={
    //     medical_id:row.medical_id
    //   }
    //   this.$axios.post(MedicalInfo,db).then(
    //     res => {
    //       if (res.data.code == 1) {
    //         var data=res.data.data;
    //         var form={
    //           vendor_id:data.vendor_id,
    //           full_name:data.full_name,
    //           specification:data.specification,
    //           arr:data.price_list,
    //           medical_id:data.medical_id
    //         }
    //         this.drugForm=form
    //         console.log(this.drugForm)
        
            
    //         this.durgVisible = true;
    //       } 
    //     },
    //     err => {}
    //   );
    },
    clearForm(){},
    //导出数据
    deriveBtn() {
        var ids = [];
        this.selectList.filter(val => {
          ids.push(val.medical_org_id);
        });
        this.common.communalApi.derivesXLS({
          url: '',
          db: {
            medical_org_ids:ids.join(','),
          }
        });
    },
    // 导入
    actionUrl() {
      return  '';
    },
    handleAvatarSuccess(res, file) {
      if(res.code==1){
        this.$message({
          message: res.msg,
          type: 'success'
        });
        this.getList();
      }else{
        this.$message.error(res.msg);
      }
    },
    beforeAvatarUpload(file) {
      const extension =
          file.name.substring(file.name.lastIndexOf(".")) === ".xls";
      const extension2 =
          file.name.substring(file.name.lastIndexOf(".")) === ".xlsx";
      const extension3 =
          file.name.substring(file.name.lastIndexOf(".")) === ".XLS";
      const extension4 =
          file.name.substring(file.name.lastIndexOf(".")) === ".XLSX";
      if (!extension && !extension2 && !extension3 && !extension4) {
          this.$message({
            message: "导入文件只能是Excel表格!",
            type: "warning"
          });
      }
      return (extension || extension2|| extension3 || extension4 );
    },
  }
};
</script>

<style lang="scss">

</style>
